export const locale = {
    lang: 'kh',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': 'ផ្ទាំងគ្រប់គ្រង ',
            'SALES': 'ការលក់',
            'PROFILE': 'ពត៌មានគណនី',
            'VEHICLE': 'ចុះបញ្ជីយានយន្ត',
            'DRIVING-LICENSE': 'ប័ណ្ណបើកបរ',
            'Vehicle-Information': 'ស្ថិតិព័ត៌មានយានយន្ត',
            'VEHICLE-by-vehicle-info': 'ស្ថិតិព័ត៌មានយានយន្ត',
            'VEHICLE-by-owner-address': 'ស្ថិតិអាស័យដ្ឋានម្ចាស់កម្មសិទ្ធិ',
            'VEHICLE-by-service-location': 'ស្ថិតិទីកន្លែងសេវាកម្ម',
            'VEHICLE-service-type': 'ស្ថិតិតាមប្រភេទសេវា',
            'VEHICLE-by-nationality': 'ស្ថិតិសញ្ញជាតិ',
            'VEHICLE-of-sold-special-plate-number': 'ស្ថិតិការលក់ផ្លាកលេខពិសេស',
            'report-of-vehicle-history': 'របាយការណ៍ប្រវត្តិយានយន្ត',
            'report-by-address': 'របាយការណ៍អាស័យដ្ឋាន',
            'report-by-service-location': 'របាយការណ៍ទីតាំងផ្តល់សេវា',
            'report-of-sold-special-plate-number': 'របាយការណ៍ការលក់ផ្លាកលេខពិសេស',
            'report-of-vehicle-registration-request-tracking': 'របាយការណ៍សំណើរការតាមដានការចុះបញ្ជីយានយន្ត្',
            'VEHICLE-by-license-type-and-service': 'ស្ថិតិប្រភេទប័ណ្ណបើកបរនិងសេរាកម្ម',
            'VEHICLE-by-address': 'ស្ថិតិអាស័យដ្ឋាន',
            'VEHICLE-by-age': 'ស្តិតិអាយុ',
            'report-service-type': 'របាយការណ៍ប្រភេទសេវាកម្ម',
            'report-by-nationality': 'របាយការណ៍សញ្ញជាតិ',
            'report-by-age': 'របាយការណ៍អាយុ',
            'report-of-driver-license-history': 'របាយការណ៍ប្រវត្តិប័ណ្ណបើកបរ',
            'report-of-driver-license-request-tracking': 'របាយការណ៍សំណើរតាមដានប័ណ្ណបើកបរ',
            'setting-security-role': 'ក្រុមសិទ្ធ',
            'USER': 'អ្នកប្រើប្រាស់',
            'SETUP': 'កំណត់',
            'Dashboard': 'ផ្ទាំងគ្រប់គ្រង',
            'Sale': 'ការលក់',
            'Purchase': 'បញ្ជាទិញ',
            'Order': 'ទិញផលិតផល',
            'Stock': 'ស្តុក',
            'Network': 'តំណរ',
            'Finance': 'ហិរញ្ញវត្ថុ',
            'Product': 'ផលិតផល',
            'Setting': 'ការកំណត់',
            'Member': 'សមាជិក',
            'User': 'អ្នកប្រើប្រាស់',
            'CATEGORY': 'ប្រភេទផលិតផល',
            'Distributor': 'តំណាងចែកចាយ',


        }
    }
};

import {FuseNavigation} from '@fuse/types';

export const cpNavigation: FuseNavigation[] = [

    {
        id: 'applications',
        title: 'Menus',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [

            //===================================>> Dashboard
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/cp/dashboard',
                children: [],
            },

            //===================================>> Proposal
            {
                id: 'proposals',
                title: 'Proposals',
                translate: 'Proposals',
                type: 'collapsable',
                icon: 'list',
                children: [

                    {
                        id: 'proposal',
                        title: 'All Proposals',
                        translate: 'All Proposals',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/proposals/all'
                    },
                    {
                        id: 'proposal-status',
                        title: 'Status',
                        translate: 'Status',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/proposals/status'
                    },
                    {
                        id: 'proposal-round',
                        title: 'Round',
                        translate: 'Round',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/proposals/round'
                    },

                ],
            },

            //===================================>> Project
            {
                id: 'projects',
                title: 'Projects',
                translate: 'Projects',
                type: 'collapsable',
                icon: 'star',
                children: [
                    {
                        id: 'All Projects',
                        title: 'All Projects',
                        translate: 'All Projects',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/projects'
                    },
                    // {
                    //     id: 'project-status',
                    //     title: 'Status',
                    //     translate: 'Status',
                    //     type: 'item',
                    //     icon: 'keyboard_arrow_right',
                    //     url: '/cp/project-status'
                    // }
                ],
            },

            //===================================>> Officer
            {
                id: 'officers',
                title: 'Officers',
                translate: 'Officers',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: 'All',
                        title: 'All',
                        translate: 'All',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/officers/all'
                    },
                    // {
                    //     id      : 'officer-proposal-role',
                    //     title   : 'Proposal Role',
                    //     translate:'Proposal Role',
                    //     type    : 'item',
                    //     icon     : 'keyboard_arrow_right',
                    //     url     : '/cp/officer/proposal-role'
                    // },
                    // {
                    //     id      : 'officer-project-role',
                    //     title   : 'Project Role',
                    //     translate: 'Project Role',
                    //     type    : 'item',
                    //     icon     : 'keyboard_arrow_right',
                    //     url     : '/cp/officer/project-role'
                    // },
                    {
                        id: 'officer-role',
                        title: 'Role',
                        translate: 'Role',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/officer/roles'
                    }
                ],
            },

            //===================================>> Permission
            {
                id: 'permission',
                title: 'System Permission',
                translate: 'System Permission',
                type: 'item',
                icon: 'fingerprint',
                url: '/cp/system-permission',
                children: [],
            },

            //===================================>> School
            {
                id: 'School',
                title: 'School',
                translate: 'School',
                type: 'item',
                icon: 'school',
                url: '/cp/schools',
                children: [],
            },

            //===================================>> Company
            {
                id: 'Company',
                title: 'Company',
                translate: 'Company',
                type: 'item',
                icon: 'business',
                url: '/cp/companies'
            },

            //===================================>> Trainer
            {
                id: 'trainer',
                title: 'Trainer',
                translate: 'Trainer',
                type: 'item',
                icon: 'group',
                url: '/cp/trainers',
                children: [],
            },

            //===================================>> Trainee
            {
                id: 'trainee',
                title: 'Trainee',
                translate: 'Trainee',
                type: 'item',
                icon: 'group',
                url: '/cp/trainees'
            },

            //===================================>> PMU Financial
            {
                id: 'pmu-financial',
                title: 'PMU Financial',
                translate: 'PMU Financial',
                type: 'collapsable',
                icon: 'pie_chart',
                children: [
                    {
                        id: 'Budget Category',
                        title: 'Budget Category',
                        translate: 'Budget Category',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/pmu/budget-categories'
                    },

                    {
                        id: 'Donor',
                        title: 'Donor',
                        translate: 'Donor',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/pmu/donors'
                    },
                ],
            },


            //===================================>> Evaluation
            {
                id: 'kpi',
                title: 'Indicator',
                translate: 'Indicator',
                type: 'item',
                icon: 'tune',
                url: '/cp/indicators',
            },

            //===================================>> Setup
            {
                id: 'setups',
                title: 'Setups',
                translate: 'Setups',
                type: 'collapsable',
                icon: 'settings',
                children: [
                    {
                        id: 'Sector',
                        title: 'Sectors',
                        translate: 'Sectors',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/sectors'
                    },
                    {
                        id: 'Proposal Scheme',
                        title: 'Proposal Scheme',
                        translate: 'Proposal Scheme',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/proposal-schemes'
                    },
                    {
                        id: 'Proposal Status',
                        title: 'Proposal Status',
                        translate: 'Proposal Status',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/proposal-status'
                    },
                    {
                        id: 'Project Status',
                        title: 'Project Status',
                        translate: 'Project Status',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/project-status'
                    },
                    {
                        id: 'School Type',
                        title: 'School Type',
                        translate: 'School Type',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/school-types'
                    },
                    {
                        id: 'Document',
                        title: 'Document',
                        translate: 'Document',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/documents'
                    },

                    {
                        id: 'Cost',
                        title: 'Cost',
                        translate: 'Cost',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/costs'
                    },
                    {
                        id: 'Exchange-Rate',
                        title: 'Exchange-Rate',
                        translate: 'Exchange-Rate',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/exchange-rates'
                    },
                    {
                        id: 'evaluation-statement',
                        title: 'Evaluation Statement',
                        translate: 'Evaluation Statement',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/evaluation-statements'
                    },
                    {
                        id: 'question',
                        title: 'Question',
                        translate: 'Question',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/questions'
                    },

                    {
                        id: 'financial-step',
                        title: 'Financial Step',
                        translate: 'Financial Step',
                        type: 'item',
                        icon: 'keyboard_arrow_right',
                        url: '/cp/setup/financial-steps'
                    },

                ],
            },

            //===================================>> My Profile
            {
                id: 'my-profile',
                title: 'My Profile',
                translate: 'My Profile',
                type: 'item',
                icon: 'person',
                url: '/my-profile',
                permission: ''
            },

            // {
            //     id      : 'Security',
            //     title   : 'Security',
            //    translate: 'Security',
            //     type    : 'item',
            //     icon     : 'keyboard_arrow_right',
            //     url     : '/security'
            // },


        ],
    },

];

export const environment = {
    production: true,
    hmr: false,
    token: 'cp-token',

    // ===============================================>> Production
    apiUrl: '/api',
    fileUrl: 'https://pms.sgp1.cdn.digitaloceanspaces.com/',
    url: 'https://dev.pms.sdfcambodia.org',

    user: '',
    password: ''
};

export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARD': 'Dashboard ',
            'SALES': 'Sales',
            'PROFILE': 'My Profile',
            'VEHICLE': 'Vehicle Registration',
            'DRIVING-LICENSE': 'Driver license',
            'Vehicle-Information': 'Vehicle Information Statistics',
            'SETUP': 'Set Up',
            'CATEGORY': 'Category Product',
            'Distributor': 'Distributor',


        }

    }
};

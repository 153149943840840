import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByTypeId'
})
export class FilterByTypeIdPipe implements PipeTransform {
    transform(indicators: any[], typeId: number): any[] {
        if (!indicators || !typeId) {
            return indicators;
        }
        return indicators.filter(indicator => indicator.type_id === typeId);
    }
}

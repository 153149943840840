import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
    transform(value: number | string): string {
        if (value === null || value === undefined) {
            return '0';
        }

        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(numericValue)) {
            return value.toString();
        }

        const formattedValue = numericValue.toFixed(2).replace(/\.?0+$/, '');
        return formattedValue;
    }
}
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OperationService {
    private subject = new Subject<any>();
    private echartsSource = new Subject<boolean>();
    echartsState$ = this.echartsSource.asObservable();

    send(data: string) {
        this.subject.next(data);
    }

    clear() {
        this.subject.next(null);
    }

    get(): Observable<any> {
        return this.subject.asObservable();
    }

    updateEChartsState(state: boolean) {
        // console.log('echarts state: ' + state)
        this.echartsSource.next(state);
    }
}
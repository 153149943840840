import {FuseNavigation} from '@fuse/types';

export const portalNavigation: FuseNavigation[] = [

    {
        id: 'applications',
        title: 'Menus',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard',
                //hidden:true, 
                permission: 'dashboard-list',
                children: [],
            },

            {
                id: 'proposals',
                title: 'Proposals',
                translate: 'Proposals',
                type: 'item',
                icon: 'store',
                url: '/proposals',
                permission: 'proposals-list',
                children: [],
            },

            {
                id: 'projects',
                title: 'Projects',
                translate: 'Projects',
                type: 'item',
                icon: 'view_list',
                url: '/projects',
                permission: 'proposals-list',
                children: [],
            },

            {
                id: 'schools',
                title: 'Schools',
                translate: 'Schools',
                type: 'item',
                icon: 'account_balance',
                url: '/schools',
                permission: 'Schools-list',
                children: [],
            },
            {
                id: 'companies',
                title: 'Companies',
                translate: 'Companies',
                type: 'item',
                icon: 'location_city',
                url: '/companies',
                permission: 'Companies-list',
                children: [],
            },
            {
                id: 'trainers',
                title: 'Trainers',
                translate: 'Trainers',
                type: 'item',
                icon: 'people',
                url: '/trainers',
                permission: 'trainers-list',
                children: [],
            },
            // {
            //     id       : 'trainees',
            //     title    : 'Trainees',
            //     translate: 'Trainees',
            //     type     : 'item',
            //     icon     : 'people',
            //     url      : '/trainees',
            //     permission: 'trainees-list',
            //     children : [],
            // }, 

            {
                id: 'profile',
                title: 'Profile',
                translate: 'Profile',
                type: 'item',
                icon: 'person',
                url: '/profile',
                permission: ''
            },

        ],
    },


];

import {FuseNavigation} from '@fuse/types';

const permissionStr = localStorage.getItem('permission');
let permission: any = {};
let slug: any;
if (permissionStr && permissionStr != 'undefined') {
    permission = JSON.parse(permissionStr);
}
slug = localStorage.getItem('slug');
export const officeNavigation: FuseNavigation[] = [

    {
        id: 'applications',
        title: 'Menus',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [

            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                url: 'office/dashboard',
                children: [],
            },
            {
                id: 'proposals',
                title: 'Proposals',
                translate: 'Proposals',
                type: 'collapsable',
                icon: 'libray_book',
                hidden: !permission.office_proposal,
                children: [

                    //=============================================>> Supporter
                    {
                        id: 'proposal-new-submit',
                        title: 'Newly Created',
                        translate: 'Newly Created',
                        type: 'item',
                        hidden: !permission.office_proposal_newly_created,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/newly-created'
                    },

                    {
                        id: 'proposal-new-submit',
                        title: 'Newly Submitted',
                        translate: 'Newly Submitted',
                        type: 'item',
                        hidden: !permission.office_proposal_newly_submitted,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/newly-submitted'
                    },

                    {
                        id: 'proposal-my-support',
                        title: 'My Support',
                        translate: 'My Support',
                        type: 'item',
                        hidden: !permission.office_proposal_my_support,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/my-support'
                    },


                    //=============================================>> LPS
                    {
                        id: 'proposal-newly-reviewed',
                        title: 'Newly Reviewed',
                        translate: 'Newly Reviewed',
                        type: 'item',
                        hidden: !permission.office_proposal_newly_reviewed,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/newly-reviewed'
                    },

                    {
                        id: 'proposal-monitoring',
                        title: 'My Monitoring',
                        translate: 'My Monitoring',
                        type: 'item',
                        hidden: !permission.office_proposal_my_monitoring,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/my-monitoring'
                    },

                    {
                        id: 'proposal-result',
                        title: 'Result',
                        translate: 'Result',
                        type: 'item',
                        hidden: !permission.office_proposal_result,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/result'
                    },

                    {
                        id: 'problem-proposal',
                        title: 'Problem',
                        translate: 'Problem',
                        type: 'item',
                        hidden: !permission.proposal_problem,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/problem-proposal'
                    },

                    //=============================================>> PS

                    {
                        id: 'assigned-scoring',
                        title: 'Assigned Scoring',
                        translate: 'Assigned Scoring',
                        type: 'item',
                        hidden: !permission.office_proposal_my_scoring,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/assigned-scoring'
                    },

                    {
                        id: 'current-scoring',
                        title: 'Current Scoring',
                        translate: 'Current Scoring',
                        type: 'item',
                        hidden: !permission.office_proposal_my_scoring,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/current-scoring'
                    },

                    {
                        id: 'submitted-scoring',
                        title: 'Submitted Scoring',
                        translate: 'Submitted Scoring',
                        type: 'item',
                        hidden: !permission.office_proposal_my_scoring,
                        icon: 'keyboard_arrow_right',
                        url: '/office/proposals/submitted-scoring'
                    },

                ],
            },


            {
                id: 'projects',
                title: 'Projects',
                translate: 'Projects',
                type: 'item',
                icon: 'list',
                url: '/office/projects',
                hidden: !permission.office_project,
                children: [],
            },

            {
                id: 'training-projects',
                title: 'Training Projects',
                translate: 'Training Projects',
                type: 'item',
                icon: 'school',
                url: '/office/training-projects',
                hidden: !permission.training_project,
                children: [],
            },

            {
                id: 'pmu-finances',
                title: 'PMU Finance',
                translate: 'PMU Finance',
                type: 'item',
                icon: 'credit_card',
                url: '/office/pmu-finances',
                hidden: !permission.pmu_finance,
                children: [],
            },

            {
                id: 'budget-donors',
                title: 'Budget Donors',
                translate: 'Budget Donors',
                type: 'item',
                icon: 'account_balance',
                url: '/office/donors',
                hidden: !permission.budget_donor,
                children: [],
            },

            {
                id: 'my-profile',
                title: 'My Profile',
                translate: 'My Profile',
                type: 'item',
                icon: 'person',
                url: '/my-profile',
                permission: ''
            },
        ],
    }

];



import {FuseNavigation} from '@fuse/types';

import {cpNavigation} from './cp.navigation';
import {officeNavigation} from './office.navigation';
import {portalNavigation} from './portal.navigation';

const panel = localStorage.getItem('panel');

let tempNav: FuseNavigation[] = [];
if (panel == 'cp') {
    tempNav = cpNavigation;
} else if (panel == 'office') {
    tempNav = officeNavigation;
} else if (panel == 'portal') {
    tempNav = portalNavigation;
}

export const navigation: FuseNavigation[] = tempNav; 
